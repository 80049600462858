@charset "UTF-8";

#featured {
  width: 560px;
  position: relative;
  height: 210px;
  overflow: hidden;
  background: #555;
  ul.ui-tabs-nav {
    position: absolute;
    top: 0;
    left: 389px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 170px;
    height: 210px;
    overflow: hidden;
    z-index: 2;
    li {
      padding: 1px 0 0 0;
      font-size: 11px;
      color: #666;
      span {
        font-size: 11px;
        line-height: 18px;
      }
    }
  }
  li.ui-tabs-nav-item a {
    display: block;
    height: 17px;
    text-decoration: none;
    color: #FFF;
    background: #111;
    line-height: 18px;
    outline: none;
    padding: 12px 10px;
    filter: alpha(opacity = 60);
    opacity: 0.6;
    &:hover {
      background: #000;
      filter: alpha(opacity = 100);
      opacity: 1;
    }
  }
  ul.ui-tabs-nav li {
    &.ui-tabs-selected a, &.ui-tabs-active a {
      background: #ffffff;
      color: #333;
      filter: alpha(opacity = 100);
      opacity: 1;
    }
  }
  .ui-tabs-panel {
    width: 560px;
    height: 210px;
    background: #999;
    position: relative;
    .info {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 70px;
      background: url('images/transparent-bg.png');
      a.hideshow {
        position: absolute;
        font-size: 11px;
        font-family: Verdana;
        color: #f0f0f0;
        right: 10px;
        top: -20px;
        line-height: 20px;
        margin: 0;
        outline: none;
        background: #333;
      }
    }
  }
  .info {
    h2 {
      font-size: 1.2em;
      font-family: Georgia, serif;
      color: #fff;
      padding: 5px;
      margin: 0;
      font-weight: normal;
      overflow: hidden;
    }
    p {
      margin: 0 5px;
      font-family: Verdana;
      font-size: 11px;
      line-height: 15px;
      color: #f0f0f0;
    }
    a {
      text-decoration: none;
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .ui-tabs-hide {
    display: none;
  }
}

.imgsize {
  width: 560px;
  height: 210px;
}

.banner__report_freepass {
  padding: 0 0 20px;
  text-align: center;
}